import { 
    Box, 
    Button, 
    Heading, 
    HStack, 
    Stack, 
    Tag, 
    VStack, 
    Text, 
    ButtonProps,
    Icon,
    Flex
} from "@chakra-ui/react";
import { FaBox } from "react-icons/fa";

export enum BundleType {
    OneTime,
    Subscription
}

type BundleProps = {
    name: string;
    type: BundleType;
    quantity: number;
    amount: number;
}

export default function Bundle({
    name,
    type,
    quantity = 0,
    amount,
    onClick
}: BundleProps & Pick<ButtonProps, "onClick">) {

    return (
        <Box 
            minW="340px"
            borderWidth={1}
            borderRadius={10}
            boxShadow="md"
            bgColor="white"
            _hover={{
                backgroundColor: "orange.100"
            }}
        >
            <VStack spacing={6}>

                {/* TITOLO E DESCRIZIONE */}
                <VStack w="95%">
                    <Stack 
                        h="80px"
                        w="90%"
                        borderBottomWidth={2}
                        borderBottomColor="perla.500"
                        justifyContent="end"
                        pb={2}
                        mb={6}
                    >
                        <Flex>
                            <Heading 
                                fontSize={[24, 26, 26, 28, 30]}
                                fontFamily="BabyEliot" 
                                color="perla.500"
                            >{name.toUpperCase()}</Heading>
                            {name == "bronze" ? 
                                <Icon 
                                as={FaBox} 
                                mx={4} 
                                w="24px" 
                                h="24px" 
                                color="#b08d57"
                                alignSelf="center"
                            /> : null}
                            {name == "silver" ? 
                                <Icon 
                                as={FaBox} 
                                mx={4} 
                                w="34px" 
                                h="34px" 
                                color="silver"
                                alignSelf="center"
                            /> : null}
                            {name == "gold" ? 
                                <Icon 
                                as={FaBox} 
                                mx={4} 
                                w="44px" 
                                h="44px" 
                                color="goldenrod"
                                alignSelf="center"
                            /> : null}
                        </Flex>
                    </Stack>
                    <Flex alignItems="center">
                        <Text 
                            fontSize={[20, 20, 20, 22, 22]} 
                            color="perla.800"
                            pr={2}
                        >
                            Fino a
                        </Text>
                        <Tag 
                            fontSize={[28, 28, 28, 28, 28]} 
                            bgColor="perla.500" 
                            color="white" 
                            borderRadius={30} 
                            h="60px" 
                            w="60px"
                            justifyContent="center"
                        >{quantity}</Tag>
                    </Flex>
                    <Text 
                        fontSize={[28, 28, 28, 28, 28]} 
                        color="perla.800"
                    >
                        video disponibili
                    </Text>
                </VStack>
                
                {/* PREZZO */}
                <VStack>
                    <HStack py={4}>
                        <Text 
                            fontWeight="bold" 
                            fontSize={[30, 30, 32, 34, 34]} 
                            color="perla.500"
                        >
                            {amount < 1 ? amount.toPrecision(2) : amount}€
                        </Text>
                        <Text
                            fontSize={[22, 24, 24, 26, 26]}
                            color="perla.800"
                        >
                            {type == BundleType.OneTime ? "per video" : "/ al mese"}
                        </Text>
                    </HStack>
                </VStack>

                {/* ACQUISTA */}
                <VStack pb={8} w="80%">
                    <Button 
                        w="100%" 
                        fontWeight="bold" 
                        fontSize={[22, 24, 24, 26, 26]} 
                        bgColor="perla.500" 
                        color="white"
                        size="lg"
                        _hover={{
                            backgroundColor: "orange.500"
                        }}
                        onClick={onClick}
                    >ABBONATI
                    </Button>
                </VStack>

            </VStack>
        </Box>
    );
}