import { 
  HStack, 
  Link, 
  Image, 
  Stack, 
  Text, 
  Button, 
  Tag, 
  TagLabel, 
  TagLeftIcon, 
  SimpleGrid, 
  Icon, 
  Flex, 
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  MenuDivider,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { FaEdit, FaLock, FaUser, FaUserCircle } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { NavLink, useNavigate } from "react-router-dom";
import HamburgerMenu from './Components/HamburgerMenu';
import SocialBar from './Components/SocialBar';
import useAuth from './Hooks/useAuth';
import Logo from './Logo.webp';

export type MenuVoice = {
  id: string;
  name: string;
  to: string;
}

// list of menu items
const menuAdmin = [
  { id: 'item-01', name: 'Home', to: '/' },
  { id: 'item-02', name: 'Chi siamo', to: '/chi-siamo'  },
  { id: 'item-03', name: 'Cosa facciamo', to: '/cosa-facciamo'  },
  { id: 'item-04', name: 'Video', to: '/video'  },
  { id: 'item-05', name: 'Admin', to: '/admin'  }
] as MenuVoice[];

const menuAuthenticated = [
  { id: 'item-01', name: 'Home', to: '/' },
  { id: 'item-02', name: 'Chi siamo', to: '/chi-siamo'  },
  { id: 'item-03', name: 'Cosa facciamo', to: '/cosa-facciamo'  },
  { id: 'item-04', name: 'Video', to: '/video'  }
] as MenuVoice[];

export const menuPublic = [
  { id: 'item-01', name: 'Home', to: '/' },
  { id: 'item-02', name: 'Chi siamo', to: '/chi-siamo'  },
  { id: 'item-03', name: 'Cosa facciamo', to: '/cosa-facciamo'  }
] as MenuVoice[];

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

export default function Header() {
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  const navigate = useNavigate();
  const { 
    isAuthenticated, 
    user,
    role,
    onLogout
} = useAuth();

  const menu = useMemo(() => {
    if (!isAuthenticated)
    {
      return menuPublic;
    }

    if (isAuthenticated && role.name == "Administrator")
    {
      return menuAdmin;
    }

    return menuAuthenticated;

  }, [isAuthenticated, role]);

  return (
    <Stack className="header" zIndex={100} spacing={0}>
      
      {/* TOP BAR */}
      <HStack bg="perla.500" w="100%">
        <SimpleGrid 
          columns={2} 
          w="70%" 
          py={2} 
          gap={2}
        >
          <HStack justifyContent="center" alignItems="center" pl={[8, 8, 6, 4]}>
            <Icon as={MdOutlineEmail} w="28px" h="28px" color="white"/>
            <Link 
              href='mailto:info@perlalis.it'
            >
              <Text 
                color="white" 
                fontSize={["16", "18", "18", "20", "20"]}
              >info@perlalis.it</Text>
            </Link>
          </HStack>
          <SocialBar fontSize={36}/>
        </SimpleGrid>

        <SimpleGrid 
          columns={[1, 1, 1]} 
          w="30%"
          py={2} 
          gap={2}
          justifyItems="end" 
          alignItems="center"
        >
          <HStack justifySelf="center" spacing={4}>
            {!isAuthenticated 
              ?
                (
                  <Button
                  color="perla.500"
                  backgroundColor="white"
                  fontSize={["12", "16", "18", "18", "18"]}
                  fontWeight="bold"
                  onClick={() => !isAuthenticated ? 
                    navigate("/login") : 
                    onLogout()
                  }
                  _hover={{
                    backgroundColor: "perla.700",
                    color: "perla.100",
                  }}
                >
                  <Icon as={FaLock} boxSize={["12px", "16px", "18px", "18px", "18px"]} mr={2}/>
                  {!isAuthenticated ? "ENTRA" : "ESCI"}
                </Button>
                )
              :
                (
                  <Menu gutter={3}>
                    <MenuButton 
                      as={IconButton} 
                      icon={<FaUser size={24}/>}
                      aria-label='Profilo'
                      color="white"
                      borderWidth={0}
                      bgColor="perla.500"
                      _hover={{
                        backgroundColor: "perla.700"
                      }}
                      _active={{
                        backgroundColor: "perla.600"
                      }}
                    />
                    <MenuList zIndex={9999} pl={2} pr={4} borderWidth={2}>
                      <VStack alignItems="baseline" pl={2} minW="fit-content" spacing={1}>
                        <Flex alignItems="center" justifyContent="center">
                          <Text 
                            fontWeight="bold" 
                            fontSize={["14", "18", "20", "20", "20"]}
                            color="perla.700"
                            pr={2}
                          >{user.name}</Text>
                          <Text 
                            fontSize={["12", "14", "16", "16", "16"]}
                            color="perla.600"
                          >({user.email})
                          </Text>    
                        </Flex>
                        <Text 
                          fontSize={["12", "14", "16", "16", "16"]}
                          color="perla.600"
                        >{user.role.name == "Administrator" ? "Amministratore" : "Cliente"}
                        </Text>                   
                      </VStack>
                      <MenuDivider/>
                      <MenuItem 
                        fontSize={["14", "16", "18", "18", "18"]}
                        fontWeight="bold"
                        color="perla.700"
                        onClick={() => onLogout()}
                      >Esci</MenuItem>
                    </MenuList>
                  </Menu>
                )
            }
            {!isAuthenticated 
              ? 
                (
                  <Button
                    variant="solid"
                    color="white"
                    backgroundColor="perla.600"
                    fontSize={["12", "16", "18", "18", "18"]}
                    fontWeight="bold"
                    onClick={() => navigate("/register")}
                    _hover={{
                      backgroundColor: "perla.300",
                      color: "perla.700",
                    }}
                  >
                  <Icon as={FaEdit} boxSize={["12px", "16px", "18px", "18px", "18px"]} mr={2}/>
                  <Text>REGISTRATI</Text>
                </Button>
                )
              : null
            }
          </HStack>
        </SimpleGrid>
      </HStack>
  
      {/* LOGO & NAVBAR */}
      <HStack 
        pb={3}
        pt={2} 
        backgroundColor="white"
        borderBottomWidth={2}
        borderRadius={2}
        boxShadow="md"
      >
        <Stack w="20%">
          <Flex>
            <Image
                src={Logo} 
                alt="Perlalis"
                height="auto"
                width="100px"
                maxWidth="90%"
                pl={18}
            />
            <Tag
              alignSelf="end"
              color="white" 
              bgColor="gray.500"
              fontFamily="Calibri"
              fontSize={[16, 18, 20, 22, 24]}
              fontWeight="bold"
              ml={[-4, -4, -5]}
              mb={-1}
              px={[2, 2, 3]}
              borderRadius={20}
              minW="fit-content"
            >
              Beta
            </Tag>
          </Flex>
        </Stack>
            
        {windowSize.innerWidth < 1080 ?
         <Stack 
            w="80%"
            alignItems="end"
          >
            <HamburgerMenu items={menu}/>
          </Stack> :
          <HStack 
            w="80%" 
            spacing={[4, 4,  6, 8, 10]}
            fontFamily="BabyEliot"
            fontSize={["22", "24", "26", "28", "30"]} 
            fontWeight="normal"
            color="perla.700"
          >
            {menu.map((item) => {
              return (
                <Link 
                  key={`menu-${item.id}`}
                  as={NavLink}
                  to={item.to} 
                  _hover={{
                    color: "orange.500",
                    fontWeight: "bold"
                  }}
                  _activeLink={{
                    color: "perla.500",
                  }}
                >
                    <Text textAlign="center">{item.name.toUpperCase()}</Text>
                </Link>
              );
            })}
          </HStack>
        }
      </HStack>

    </Stack>
  );
}