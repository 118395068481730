export enum PlanType {
  Bronze = "Bronze",
  Silver = "Silver",
  Gold = "Gold",
  None = "Nessuno"
}

export const getPlanType = (productId?: string) => {
    
    if (!productId) return PlanType.None;

    switch(productId)
    {
      case "prod_McAZ8QI7VhB1IB":
        return PlanType.Bronze;
      case "prod_McBEHm2Eg156M5":
        return PlanType.Silver;
      case "prod_McBFTC4rCf6THI":
        return PlanType.Gold;
      default:
        return PlanType.None;
    }
  } 