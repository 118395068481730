import React from 'react';
import { 
  useMutation, 
  useQuery 
} from 'react-query';
import { 
  Box, 
  Heading, 
  Icon, 
  SimpleGrid, 
  Spinner, 
  Stack, 
  VStack, 
  Text, 
  useDisclosure 
} from '@chakra-ui/react';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import Slider from './Components/Slider';

// CSS
import '../../Styles/HomePage.css';
import Bundle, { BundleType } from '../../Components/Bundle';
import axios from 'axios';
import useAuth from '../../Hooks/useAuth';
import CountUp from 'react-countup';
import { useLocation, useNavigate } from "react-router-dom";
import { StrapiOrderResponse, StrapiVideoResponse } from '../../ExternalTypes';
import qs from 'qs';
import { OrderFoundModal } from './Components/OrderFoundModal';
import { AdminFoundModal } from './Components/AdminFoundModal';

export default function HomePage() {

  const navigate = useNavigate();
  const location = useLocation();

  const { token, isAuthenticated, user } = useAuth();

  const strapiHeaders = {
    'Authorization': token ? `Bearer ${token}` : '',
    'Content-Type': 'application/json',
  };

  const { isLoading: isStrapiFreeVideosLoading, isError: isStrapiFreeVideosError, data: strapiFreeVideos } = useQuery(
    ["strapi", "videos", "free"], async () => {
      const query = qs.stringify({
        sort: ['createdAt:desc'],
        filters: {
          Tags: {
            $containsi: "free",
          }
        },
        populate: '*',
        pagination: {
          page: 1,
          pageSize: 6,
        },
      }, {
        encodeValuesOnly: true, // prettify URL
      });

      return await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/videos?${query}`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data;
        });
  });

  const { isLoading: isStrapiVideosLoading, isError: isStrapiVideosError, data: strapiVideos } = useQuery(
    ["strapi", "videos"], 
    async () => {
      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data;
        });
  });

  const { isLoading: isStrapiCategoriesLoading, isError: isStrapiCategoriesError, data: strapiCategories } = useQuery(
    ["strapi", "categories"], 
    async () => {
      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/categories`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data;
        });
    }
  );

  const { isLoading: isOrderLoading, data: userOrder } = useQuery(
    isAuthenticated 
      ? ["orders", user.id]
      : ["orders", "none"], 
    async () => {
      const query = qs.stringify({
        sort: ['createdAt:asc'],
        filters: {
          User: {
            id: {
              $eq: isAuthenticated ? user.id : "none",
            }
          },
        },
        populate: '*'
      }, {
        encodeValuesOnly: true, // prettify URL
      });

      return isAuthenticated 
        ? await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders?${query}`, 
          {
            headers: strapiHeaders
          })
          .then((response) => {
            return response.data.data[0] ? response.data.data[0] as StrapiOrderResponse : null;
          })
        : null;
    }
  );

  type CheckoutSessionValues = {
    priceId: string;
    client_reference_id: string;
    customer_email?: string;
  }
  
  const { mutateAsync: createCheckoutSession, isLoading: isCreateOrderLoading } = useMutation(
    async (values: CheckoutSessionValues) => {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/orders/createCheckoutSession`, 
      {
        data: values
      }, 
      {
        headers: strapiHeaders
      })
      .then(r => 
        {
          console.log({r});
          window.location.href = r.data.url;
          // setClientSecret(r.data.ClientSecret);
        });
    }
  );

  const {
    isOpen: isOrderFoundModalOpen,
    onOpen: onOrderFoundModalOpen,
    onClose: onOrderFoundModalClose,
  } = useDisclosure();

  const {
    isOpen: isAdminFoundModalOpen,
    onOpen: onAdminFoundModalOpen,
    onClose: onAdminFoundModalClose,
  } = useDisclosure();

  return (
    <Stack pb={20}>

      {/* SEZIONE SLIDER */}
      <Heading 
        alignSelf="center"
        color="perla.500"
        fontFamily="BabyEliot"
        fontSize={[34, 44, 44, 54, 64]}
        py={4}
      > Con Perlalis puoi...
      </Heading>

      <Slider />

      {/* SEZIONE COUNTER */}
      <Stack 
        alignItems="center" 
        pt={20} pb={20}
        width="100%"
      >
        <SimpleGrid 
          columns={[1, 1, 2, 2]} 
          gap={20} 
          width="80%"
          justifyItems="center"
        >
          <Box
            backgroundColor="perla.500"
            color="white"
            h={200}
            w={200}
            borderRadius={100}
            textAlign="center"
          >
          {isStrapiVideosLoading ? 
            <Stack
              spacing={0}
              h="100%" 
              w="100%"
              justifyContent="center"
            >
              <Spinner 
                size="lg" 
                alignSelf="center"
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color="white"
              />
            </Stack> :
            <CountUp 
              start={0} 
              end={isStrapiVideosError || !strapiVideos 
                ? 0
                : strapiVideos.meta.pagination.total
              }
              delay={0}
            >
              {({ countUpRef, start }) => (
                <Stack
                  spacing={0}
                  h="100%" 
                  w="100%" 
                  fontFamily="Calibri" 
                  fontSize={60} 
                  fontWeight="bold"
                  justifyContent="center"
                  onClick={start}
                  onMouseEnter={start}
                >
                  <span ref={countUpRef} />
                  <Text
                    fontFamily="Calibri" 
                    fontSize={32} 
                    fontWeight="normal"
                    fontStyle="italic"
                  >Video</Text>
                </Stack>
              )}
            </CountUp>
          }
          </Box>
          <Box
            backgroundColor="perla.500"
            color="white"
            h={200}
            w={200}
            borderRadius={100}
            textAlign="center"
          >
          {isStrapiCategoriesLoading ? 
            <Stack
              spacing={0}
              h="100%" 
              w="100%"
              justifyContent="center"
            >
              <Spinner 
                size="lg" 
                alignSelf="center"
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color="white"
              />
            </Stack> :
            <CountUp 
              start={0} 
              end={isStrapiCategoriesError || !strapiCategories 
                  ? 0
                  : strapiCategories.meta.pagination.total
              }
              delay={0}
            >
              {({ countUpRef, start }) => (
                <Stack
                  spacing={0}
                  h="100%" 
                  w="100%" 
                  fontFamily="Calibri" 
                  fontSize={60} 
                  fontWeight="bold"
                  justifyContent="center"
                  onClick={start}
                  onMouseEnter={start}
                >
                  <span ref={countUpRef} />
                  <Text
                    fontFamily="Calibri" 
                    fontSize={32} 
                    fontWeight="normal"
                    fontStyle="italic"
                  >Categorie</Text>
                </Stack>
              )}
            </CountUp>
          }
          </Box>
        </SimpleGrid>
      </Stack>

      {/* SEZIONE VIDEO DI ESEMPIO */}
      <VStack 
        py={10}
        bgColor="perla.500"
      >
        <Stack alignItems="center" pb={6}>
          <Heading 
            alignSelf="center"
            textAlign="center"
            color="white"
            fontFamily="BabyEliot"
            fontSize={[30, 40, 40, 50, 60]}
            px={2}
          > Dai un'occhiata ai nostri ultimi video
          </Heading>

          <Icon 
            as={FaArrowAltCircleDown} 
            w={20} h={20} 
            color="white"
          />
        </Stack>

        <Stack 
          p={4}
          mt={20}
          w="90%"
          borderWidth={2}
          borderRadius={10}
          boxShadow="md"
          bgColor="gray.100"
        >
          {isStrapiFreeVideosLoading 
            ? <Spinner size='lg'alignSelf="center"/> 
            : isStrapiFreeVideosError 
              ? <Text 
                  textAlign="center"
                  fontSize={[18, 20, 22, 24, 26]}
                >
                  Impossibile recuperare i video.
                </Text>
              : (
              <SimpleGrid 
                columns={[2, 2, 2, 3]} 
                spacingX={2} 
                spacingY={2} 
                bgColor="gray.200"
              >
                {strapiFreeVideos.data.map((video: StrapiVideoResponse) => {
                  return (
                    <Box 
                      key={`video-${video.attributes.Name}`}
                      className='player-wrapper' 
                      alignItems="center"
                      // _hover={{
                      //   borderColor: "perla.500",
                      //   borderWidth: 3
                      // }}
                    >
                      <ReactPlayer 
                        url={video.attributes.Link} 
                        controls 
                        className='react-player'
                        width='100%'
                        height='100%'
                      />
                    </Box>
                  );
                })}
                
              </SimpleGrid>
              )
          }
        </Stack>
      </VStack>

      {/* SEZIONE PACCHETTI */}
      <Stack 
        alignItems="center" 
        justifyContent="center" 
        pt={8}
        pb={16}
        bgColor="white"
      >
        <Heading 
          color="perla.500"
          fontFamily="BabyEliot"
          fontWeight="bold"
          fontSize={["36", "38", "42", "46", "50"]}
          alignSelf="center"
          textAlign="center"
          py={8}
        >
          Scegli una delle nostre offerte!
        </Heading>
        <SimpleGrid columns={[1, 1, 1, 3, 3]} gap={[10, 15, 20, 18, 20]}>

          <Bundle 
            name='bronze'
            type={BundleType.Subscription}
            quantity={100}
            amount={5}
            onClick={async () => 
              isAuthenticated 
                ? 
                  user.role.name === "Administrator" 
                    ? onAdminFoundModalOpen()
                    : userOrder
                      ? onOrderFoundModalOpen()
                      : createCheckoutSession({
                          priceId: "price_1LvJNpDIYYxoTAu7DQRiwQqf",
                          client_reference_id: user.id,
                          customer_email: user.email,
                        }) 
                : navigate("/login", { state: { from: location }})
            }
          />

          <Bundle 
            name='silver'
            type={BundleType.Subscription}
            quantity={300}
            amount={10}
            onClick={async () => 
              isAuthenticated 
                ? 
                  user.role.name === "Administrator" 
                    ? onAdminFoundModalOpen()
                    : userOrder
                      ? onOrderFoundModalOpen()
                      : createCheckoutSession({
                          priceId: "price_1LswriDIYYxoTAu7Y11nhoew",
                          client_reference_id: user.id,
                          customer_email: user.email,
                        }) 
                : navigate("/login", { state: { from: location }})
            }
          />

          <Bundle 
            name='gold'
            type={BundleType.Subscription}
            quantity={500}
            amount={14}
            onClick={async () => 
              isAuthenticated 
                ? 
                  user.role.name === "Administrator" 
                    ? onAdminFoundModalOpen()
                    : userOrder
                        ? onOrderFoundModalOpen()
                        : createCheckoutSession({
                            priceId: "price_1LvJObDIYYxoTAu7Z1PkYhRR",
                            client_reference_id: user.id,
                            customer_email: user.email,
                          }) 
                : navigate("/login", { state: { from: location }})
            }
          />

        </SimpleGrid>
      </Stack>

      <OrderFoundModal
        isOpen={isOrderFoundModalOpen}
        onClose={onOrderFoundModalClose}
      />
      <AdminFoundModal
        isOpen={isAdminFoundModalOpen}
        onClose={onAdminFoundModalClose}
      />

    </Stack>
  );
}