import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { 
  Alert, 
  AlertDescription, 
  AlertIcon, 
  Box, 
  Button, 
  Heading, 
  Input, 
  InputGroup, 
  InputRightElement, 
  SimpleGrid, 
  Spinner, 
  Stack, 
  Text,
  Image 
} from "@chakra-ui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Logo from '../Logo.webp';

type UserFormProps = {
  onSubmit: (values: UserValues) => void;
  title: string;
};

export type UserValues = {
  name: string;
  email: string;
  password: string;
};

export function UserForm({ 
  onSubmit,
  title
}: UserFormProps) {

  const schema = Yup.object().shape({
    name:Yup.string().required("Questo campo è obbligatorio."),
    email: Yup.string().email("Devi inserire un indirizzo email valido").required("Questo campo è obbligatorio."),
    password: Yup.string()
      .min(8, 'La password deve contenere almeno 8 caratteri.')
      .required("Questo campo è obbligatorio.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@:#\$%\^&\*\?\[\]])(?=.{8,})/,
        'La password deve contenere almeno 8 caratteri, uno maiuscolo, uno minuscolo, un numero e un carattere speciale tra i seguenti: !@:#$%^&*?[]'
      ),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordIsFilled, setShowPasswordIsFilledValue] = useState(false);
  const showPasswordHandleInput = (e: any) => {
    if (e.target.value !== "") setShowPasswordIsFilledValue(true);
    else setShowPasswordIsFilledValue(false);
  };

  return (
    <Formik<UserValues>
      validationSchema={schema}
      initialValues={{
        name: "",
        email: "",
        password: ""
      }}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched, isSubmitting }) => (
        <Form>
          <Stack 
            py={4} 
            px={[10, 10, 10, 10, 20]}
          >
            <Heading 
              pb={4} 
              alignSelf="center"
              color="perla.500"
              textAlign="center"
            >{title}</Heading>

            <Box alignSelf="center">
              <Image
                  src={Logo} 
                  alt="Perlalis"
                  height="auto"
                  width="100px"
              />
            </Box>

            <SimpleGrid 
              pb={4} 
              columns={[1, 1, 1]} 
              gap={2}
              fontSize={["18", "20", "22", "22", "24"]}
            >
              <Text fontWeight="bold" color="perla.600">Nome e cognome</Text>
              <Field 
                as={Input} 
                id="name" 
                name="name" 
                placeholder="Scrivi il tuo nome e cognome"
                fontSize={["18", "18", "20", "20", "22"]}
                focusBorderColor="perla.600"
                color="perla.800"
                mb={3}
                isRequired
              />
              <ErrorMessage name="name" render={msg => (
                <Stack>
                  <Alert status="error" min-height="28px">
                      <AlertIcon />
                      <AlertDescription fontSize="16px">{msg}</AlertDescription>
                  </Alert>
                </Stack>
              )}/>
              
              <Text fontWeight="bold" color="perla.600">Email</Text>
              <Field 
                as={Input} 
                type="email" 
                id="email" 
                name="email" 
                placeholder="Scrivi il tuo indirizzo email" 
                autocomplete="username"
                fontSize={["18", "18", "20", "20", "22"]}
                focusBorderColor="perla.600"
                color="perla.800"
                mb={3}
                isRequired
              />
              <ErrorMessage name="email" render={msg => (
                <Stack>
                  <Alert status="error" min-height="28px">
                    <AlertIcon />
                    <AlertDescription fontSize="16px">{msg}</AlertDescription>
                  </Alert>
                </Stack>
              )}/>

              <Text fontWeight="bold" color="perla.600">Password</Text>
              <InputGroup size="md">
                <Field
                  as={Input} 
                  type={showPassword ? "text" : "password"}
                  id="password" 
                  name="password" 
                  placeholder="Scrivi la tua password" 
                  autocomplete="current-password"
                  fontSize={["18", "18", "20", "20", "22"]}
                  focusBorderColor="perla.600"
                  color="perla.800"
                  mb={3}
                  onInput={showPasswordHandleInput}
                  isRequired
                />
                {showPasswordIsFilled ? (
                  <InputRightElement
                    width="4.5rem"
                    marginRight={1}
                  >
                    <Button
                      h="2rem"
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <ViewOffIcon w={6} h={6} color="red.500" />
                      ) : (
                        <ViewIcon w={6} h={6} color="perla.600" />
                      )}
                    </Button>
                  </InputRightElement>
                ) : null}
              </InputGroup>
              <ErrorMessage name="password" render={msg => (
                <Stack>
                  <Alert status="error" min-height="28px">
                    <AlertIcon />
                    <AlertDescription fontSize="16px">{msg}</AlertDescription>
                  </Alert>
                </Stack>                    
              )}/>
            </SimpleGrid>

            <Stack py={6}>
              <Button 
                type="submit" 
                size="lg" 
                py={8}
                fontWeight="bold" 
                backgroundColor="perla.600" 
                color="white"
                fontSize={["20", "22", "24", "26", "28"]}
                _hover={{
                  backgroundColor: "perla.500",
                }}
                isLoading={isSubmitting}
              >CREA ACCOUNT</Button>
            </Stack>

          </Stack>
        </Form>
      )}
    </Formik>
  );
}
